import { Link } from 'react-router-dom'

import { Dropdown } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'
import { removeAuthenticated } from 'components/adminLogin/authSlice'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const Header = () => {
	const dispatch = useDispatch()
	const { user: signInUser } = useSelector((state) => state.auth)

	const handleLogOut = () => {
		localStorage.clear()
		dispatch(removeAuthenticated())
	}

	return (
		<div className="header">
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div className="input-group search-area right d-lg-inline-flex d-none">
								<input type="text" className="form-control" placeholder="Find something here..." />
								<div className="input-group-append">
									<span className="input-group-text">
										<Link to={'#'}>
											<i className="flaticon-381-search-2"></i>
										</Link>
									</span>
								</div>
							</div>
						</div>
						<ul className="navbar-nav header-right main-notification">
							<Dropdown as="li" className="nav-item dropdown header-profile">
								<Dropdown.Toggle
									variant=""
									as="a"
									className="nav-link i-false c-pointer"
									// href="#"
									role="button"
									data-toggle="dropdown"
								>
									<img src={signInUser?.avatar} width={20} alt="" />
									<div className="header-info">
										<span>{signInUser?.displayName}</span>
										<small>Super Admin</small>
									</div>
								</Dropdown.Toggle>

								<Dropdown.Menu align="right" className="mt-2">
									<a href={frontBaseURL} className="dropdown-item ai-icon" onClick={handleLogOut}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 576 512"
											width={18}
											height={18}
											className="text-success"
										>
											<path d="M534.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L434.7 224 224 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM192 96c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-53 0-96 43-96 96l0 256c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
										</svg>
										<span className="ml-2">Logout</span>
									</a>
								</Dropdown.Menu>
							</Dropdown>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	)
}

export default Header
