import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getFanDashboardCarouselItemActions } from 'services/SeedServices'
import { showMessage } from 'utils/notification.util'
import {
	getFanDashboardCarouselItemsService,
	updateFanDashboardCarouselItemsService,
} from 'services/FanDashboardCarouselService'
import _ from 'lodash'

const useFanDashboardCarousel = () => {
	const [actions, setActions] = useState([])
	const [items, setItems] = useState([])

	const [{ loading: loadingActions }] = useAxios(
		getFanDashboardCarouselItemActions(true),
		(data, error) => {
			if (!error) {
				setActions(data)
			}
		}
	)

	const [{ loading }] = useAxios(getFanDashboardCarouselItemsService(), (data, error) => {
		if (!error) {
			setItems(data)
		}
	})

	const [{ loading: updating }, updateConfig] = useAxios(
		updateFanDashboardCarouselItemsService(
			items.map((el) =>
				_.pick(el, [
					'id',
					'title',
					'description',
					'expirationDate',
					'buttonText',
					'actionId',
					'order',
				])
			)
		),
		(data, error) => {
			if (!error) {
				setItems(data)
				const message = 'Fan Dashboard Carousel Items updated successfully!'
				showMessage({ message })
			}
		}
	)

	const handleSaveChanges = () => {
		updateConfig()
	}

	const handleAddItem = () => {
		const maxOrder = Math.max(0, ...items.map((item) => item.order))
		setItems([
			...items,
			{
				title: '',
				description: '',
				buttonText: '',
				order: maxOrder + 1,
				expirationDate: new Date()
			},
		])
	}

	const showMask = loading || loadingActions || updating

	const handleChange = (payload, idx) => {
		const newItems = [...items]
		newItems[idx] = {
			...newItems[idx],
			...payload,
		}
		setItems(newItems)
	}

	const handleRemoveItem = (idx) => () => {
		const newItems = items.filter((_el, i) => i !== idx)
		setItems(newItems)
	}

	return {
		items,
		actions,
		showMask,
		handleSaveChanges,
		handleRemoveItem,
		handleAddItem,
		handleChange,
	}
}

export default useFanDashboardCarousel
