import axios from 'axios'

const baseURL = process.env.REACT_APP_API_BASE_URL
const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

axios.defaults.baseURL = baseURL

axios.interceptors.request.use(
	async (config) => {
		const user = JSON.parse(localStorage.getItem('user'))
		const token = user?.jwt

		if (token) {
			config.headers = {
				authorization: `Bearer ${token}`,
			}
		}
		return config
	},
	(error) => Promise.reject(error)
)

axios.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error?.response?.status === 401) {
			localStorage.clear()
			window.location.href = frontBaseURL
		}
		return Promise.reject(error)
	}
)

export default axios
