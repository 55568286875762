import { createSlice } from '@reduxjs/toolkit'
import { getObjectFromLocalStorage } from 'utils/common.util'

const user = getObjectFromLocalStorage(['user']) ?? {
	user: {
		avatar: '',
		displayName: '',
		balance: '',
		id: '',
		email: '',
		isAdmin: '',
	},
}
const initialState = {
	...user,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		removeAuthenticated: (state) => {
			state.user = null
		},
		updateSignInUser: (state, { payload }) => {
			state.user = {
				...state.user,
				...payload,
			}
		},
	},
})

export const { updateSignInUser, removeAuthenticated } = authSlice.actions

export default authSlice.reducer
