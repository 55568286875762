import _ from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import Select from 'react-select'

const CustomSelect = ({
	value,
	setValue,
	options,
	fieldName = 'name',
	height = 50,
	onInputChange,
	...others
}) => {
	const [selectedOption, setSelectedOption] = useState(null)

	const parsedOptions = options.map((el) => {
		const label = el[fieldName]
		if (!_.isNil(label) && !_.isNil(el.id)) {
			const option = {
				value: el.id,
				label,
			}
			if (el.id === value && selectedOption === null && value !== null) {
				setSelectedOption(option)
			}
			return option
		}
		return el
	})

	const handleChange = (option) => {
		setSelectedOption(option)
		setValue && setValue(option.value, option.label)
	}

	const handleChangeDebounced = useMemo(() => {
		return _.debounce((value) => {
			onInputChange && onInputChange(value.trim())
		}, 500)
	}, [])

	useEffect(() => {
		if (!value) {
			setSelectedOption(null)
		}
	}, [value])

	return (
		<Select
			value={selectedOption}
			onChange={handleChange}
			options={parsedOptions}
			showContent="true"
			open="true"
			styles={{
				control: (base) => ({
					...base,
					height,
					minHeight: height,
				}),
			}}
			theme={(theme) => ({
				...theme,
				borderRadius: 0,
				colors: {
					...theme.colors,
					primary25: 'none',
					primary: '#6868681a',
					neutral80: '#fff',
				},
			})}
			menuPosition={'fixed'}
			onInputChange={handleChangeDebounced}
			{...others}
		/>
	)
}

export default CustomSelect
