import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import useUsersTable from './useUsersTable'
import { formatDate } from 'utils/date.utils'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const UsersTable = () => {
	const {
		data,
		limit,
		loading,
		campaignId,
		campaignName,
		handleSendEmail,
		setOffset,
		setLimit,
		setSearch
	} = useUsersTable()

	const columns = [
		{
			header: 'Join Date',
			render: (row) => formatDate(_.get(row, 'createdAt', null), 'inputDate'),
		},
		{
			header: 'Last Sign In',
			render: (row) => formatDate(_.get(row, 'lastSignIn', null), 'timestamp'),
		},
		{
			header: 'User',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.username}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						<img src={row?.avatar} className="rounded-md mr-2" width="24" alt="" /> {row.displayName}
					</div>{' '}
				</a>
			),
		},
		{ header: 'Username', key: 'username' },
		{ header: 'Email', key: 'email' },
		{ header: 'Campaign', key: 'campaign.name' },
		{ header: 'NC Wallet', key: 'wallet.ncWalletAddress' },
		{ 
			header: 'Ban Status', 
			render: (row) => {
				if(row?.isBanned && row?.isBlacklisted) return <div style={{color: '#BA0021', fontWeight: 'bold'}}>Full Ban</div>
				if(!row?.isBanned && row?.isBlacklisted) return <div style={{color: '#FFAC1C', fontWeight: 'bold'}}>Blacklisted</div>
			}
		 
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`/edit-user/${row.id}`} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
					<button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={handleSendEmail(row.id)}>
						<i className="fa fa-envelope"></i>
					</button>
				</div>
			),
		},
	]

	const title = campaignId ? `Campaign ${campaignName}` : 'User Management'
	const subtitle = campaignId ? 'Users' : 'Current Users'

	return (
		<>
			<PageTitle title={title} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>{subtitle}</Card.Title>

							<Link to="new-user">
								{!campaignId && <Button className="mr-2" variant="success">
									Create User
								</Button>}
							</Link>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default UsersTable
