import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import 'react-quill/dist/quill.snow.css'
import './HtmlEditor.scss'

export default function HtmlEditor({ value, setValue, label, helperText, error }) {
	const modules = {
		toolbar: [
			[{ header: [1, 2, false] }],
			['bold', 'italic', 'underline', 'strike'],
			[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			['link', 'image'],
			[{ color: ['#3CBB5B', 'red', 'green', 'blue', 'orange', 'violet', 'white', 'black'] }],
			['clean'],
		],
	}

	const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'color',
	]

	const handleChange = (html) => {
		setValue(html)
	}

	return (
		<div className={`html_editor ${error ? 'error' : ''}`}>
			{label && <h3>{label}</h3>}
			<ReactQuill
				{...{
					value,
					onChange: handleChange,
					modules,
					formats,
				}}
			/>
			{(helperText || error) && <p className="helper_text">{error || helperText}</p>}
		</div>
	)
}

HtmlEditor.propTypes = {
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.string,
	helperText: PropTypes.string,
}
