import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import useProjectsTable from './useProjectsTable'
import { formatDate } from 'utils/date.utils'
import { CustomSelect } from 'components/common/customSelect'
import styles from './ProjectsTable.module.scss'
import { CustomTable } from 'components/common/customTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { SearchField } from 'components/common/searchField'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const ProjectsTable = () => {
	const {
		data,
		loading,
		limit,
		stages,
		setLimit,
		setOffset,
		setSearch,
		setStageId,
		handleStageChange,
	} = useProjectsTable()

	const columns = [
		{
			header: 'Created On',
			render: (row) => formatDate(row.createdAt, 'inputDate'),
		},
		{
			header: 'Project',
			render: (row) => (
				<a href={`${frontBaseURL}/project/${row.slug}`} target="_blank" rel="noreferrer">
					{row.title}
				</a>
			),
		},
		{
			header: 'Status',
			render: (row) => (
				<div className="form-group">
					<CustomSelect
						{...{
							value: row.stage.id,
							setValue: (stageId) => {
								handleStageChange(row.id, stageId)
							},
							options: stages,
							className: styles.stages,
							height: 40,
						}}
					/>
				</div>
			),
		},
		{
			header: 'Creator',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.user.username}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						<img src={row?.user?.avatar} className="rounded-md mr-2" width="24" alt="" />{' '}
						{row.user.displayName}
					</div>{' '}
				</a>
			),
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`/edit-project/${row.id}`} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
				</div>
			),
		},
	]

	return (
		<>
			<PageTitle title={'Project Management'} />
			<Row className={styles.project_table}>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Projects</Card.Title>

							<div className="form-group">
								<CustomSelect
									{...{
										value: data.metricId,
										setValue: setStageId,
										options: stages,
										className: styles.stages,
									}}
								/>
							</div>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default ProjectsTable
