import { Button } from 'react-bootstrap'
import { LoadMask } from 'components/common/loadMask'
import { CustomSelect } from 'components/common/customSelect'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import useFanDashboardCarousel from './useFanDashboardCarousel'
import HtmlEditor from 'components/common/htmlEditor/HtmlEditor'

const FanDashboardCarousel = () => {
	const {
		items,
		actions,
		showMask,
		handleSaveChanges,
		handleAddItem,
		handleChange,
		handleRemoveItem,
	} = useFanDashboardCarousel()

	const renderItems = () =>
		items.map((item, i) => {
			return (
				<div className="form-row border-bottom mb-5">
					<div className="form-group col-md-6">
						<label>Title</label>
						<input
							value={item.title}
							type="text"
							className="form-control"
							placeholder="Title"
							onChange={(e) => {
								handleChange({ title: e.target.value }, i)
							}}
						/>
					</div>
					<div className="form-group col-md-6">
						<label>Button Text</label>
						<input
							value={item.buttonText}
							type="text"
							className="form-control"
							placeholder="Button Text"
							onChange={(e) => {
								handleChange({ buttonText: e.target.value }, i)
							}}
						/>
					</div>

					<div className="form-group col-md-12">
						<label>Description</label>
						<HtmlEditor
							{...{
								value: item?.description,
								setValue: (description) => {
									handleChange({ description }, i)
								}
							}}
						/>
					</div>
					<div className="form-group col-md-3">
						<label>Order</label>
						<input
							value={item.order}
							type="number"
							className="form-control"
							placeholder="Order"
							onChange={(e) => {
								handleChange({ order: e.target.value }, i)
							}}
						/>
					</div>
					<div className="form-group col-md-3">
						<label>Expiration Date </label>
						<DatePicker
							selected={new Date(item?.expirationDate)}
							onChange={(expirationDate) => handleChange({ expirationDate }, i)}
							className="form-control"
						/>
					</div>

					<div className="form-group col-md-6">
						<label>Action</label>
						<CustomSelect
							{...{
								value: item.actionId,
								setValue: (actionId) => {
									handleChange({ actionId }, i)
								},
								options: actions,
							}}
						/>
					</div>
					<div className="form-group col-md-9"></div>
					<div className="form-group col-md-3">
						<Button className="mr-2" variant="danger" onClick={handleRemoveItem(i)}>
							Remove Item
						</Button>
					</div>
				</div>
			)
		})

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Fan Dashboard Carousel</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										{renderItems()}
										<Button className="mr-2" variant="info" onClick={handleAddItem}>
											Add Item
										</Button>
										<Button className="mr-2" variant="success" onClick={handleSaveChanges}>
											Save
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default FanDashboardCarousel
