import _ from 'lodash'
import { Row, Col, Card } from 'react-bootstrap'
import useUsersDasboradTable from './useUsersDasboradTable'
import { formatDate } from 'utils/date.utils'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const UsersDasboradTable = () => {
	const { data, limit, loading, setOffset, setLimit, setSearch } =
		useUsersDasboradTable()

	const columns = [
		{
			header: 'User',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.username}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						<img src={row?.avatar} className="rounded-md mr-2" width="24" alt="" /> {row?.displayName}
					</div>{' '}
				</a>
			),
		},
		{ header: 'Username', key: 'username' },
		{ header: 'Email', key: 'email' },
		{
			header: 'Join Date',
			render: (row) => formatDate(_.get(row, 'createdAt', null), 'inputDate'),
		},
		{
			header: 'Last Sign In',
			render: (row) => formatDate(_.get(row, 'lastSignIn', null), 'timestamp'),
		},
		{ header: 'Campaign', key: 'campaign.name' },
		{ header: 'Balance', key: 'balance', textAlign: 'center' },
		{ header: 'Staking Count', key: 'stakeCount', textAlign: 'center' },
		{ header: 'Staking Volume', key: 'stakeTotal', textAlign: 'center' },
		{ header: 'FGR Total', key: 'fgrTotal', textAlign: 'center' },
		{ header: 'Reviews', key: 'reviewsCount', textAlign: 'center' },
		{ header: 'Shares', key: 'sharesCount', textAlign: 'center' },
	]

	const subtitle = 'Users'

	return (
		<Row>
			<Col lg={12}>
				<Card>
					<Card.Header>
						<Card.Title>{subtitle}</Card.Title>
						<SearchField {...{ setSearch }} />
					</Card.Header>
					<Card.Body>
						<CustomTable
							{...{
								data,
								loading,
								limit,
								setLimit,
								setOffset,
								columns,
							}}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default UsersDasboradTable
