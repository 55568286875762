export const getFanDashboardCarouselItemsService = () => ({
	method: 'get',
	url: '/fanDashboardCarouselItem',
	autoexec: true,
})

export const updateFanDashboardCarouselItemsService = (data) => ({
	method: 'put',
	url: '/fanDashboardCarouselItem',
	data,
	autoexec: false,
})
