export const getProjectStagesService = (autoexec) => ({
	method: 'get',
	url: `/seed/projectStages`,
	autoexec,
})

export const getGoScoreMetrics = (autoexec) => ({
	method: 'get',
	url: '/seed/goScoreMetrics',
	autoexec,
})

export const getUserTypes = (autoexec) => ({
	method: 'get',
	url: '/seed/userTypes',
	autoexec,
})

export const getUserGenders = (autoexec) => ({
	method: 'get',
	url: '/seed/userGenders',
	autoexec,
})

export const getProjectTypes = (autoexec) => ({
	method: 'get',
	url: '/seed/projectTypes',
	autoexec,
})

export const getProjectStatus = (autoexec) => ({
	method: 'get',
	url: '/seed/projectStatus',
	autoexec,
})

export const getCountries = (autoexec) => ({
	method: 'get',
	url: '/seed/countries',
	autoexec,
})

export const getGoScoreStages = (autoexec) => ({
	method: 'get',
	url: '/seed/goScoreStages',
	autoexec,
})

export const getFanMetrics = (autoexec = true) => ({
	method: 'get',
	url: '/seed/fanMetrics',
	autoexec,
})

export const getDaoMetrics = (autoexec = true) => ({
	method: 'get',
	url: '/seed/daoMetrics',
	autoexec,
})

export const getCollaboratorPermissions = (autoexec = true) => ({
	method: 'get',
	url: '/seed/collaboratorPermissions',
	autoexec,
})

export const getFanDashboardCarouselItemActions = (autoexec = true) => ({
	method: 'get',
	url: '/seed/fanDashboardCarouselItemActions',
	autoexec,
})
