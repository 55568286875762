import { useEffect } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import { useDispatch } from 'react-redux'
import { loginService } from 'services/LoginService'
import { showMessage } from 'utils/notification.util'
import { updateSignInUser } from './authSlice'
import { addObjectToLocalStorage } from 'utils/common.util'

function AdminLogin() {
	const { token } = useParams()
	const history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		const performLogin = async () => {
			try {
				const response = await axios(loginService(token))
				addObjectToLocalStorage({ user: response?.data })
				addObjectToLocalStorage({ token })
				dispatch(updateSignInUser(response.data))
				history.push('/')
			} catch (err) {
				console.error('Login error:', err)
				showMessage({ message: 'You are not authorized' })
				history.goBack()
				setTimeout(() => {
					window.close()
				}, 3000)
			}
		}

		performLogin()
	}, [token, dispatch, history])

	return (
		<div>
			<Spinner animation="grow" variant="light" />
		</div>
	)
}

export default AdminLogin
